<div class="container">
    <app-search (find)=onFind($event)></app-search>
    <div class="d-flex justify-content-end mb-3">
        <button id="add" type="button" class="btn btn-primary my-2 my-sm-0" (click)="onAddCourse()"><fa-icon [icon]="faPlus"></fa-icon> Add course</button>
    </div>
    <div class="list-group">
        <div class="message alert alert-info text-center" *ngIf="courseListsItems.length === 0">NO DATA, FEEL FREE TO ADD NEW COURSE</div>
        <app-course-list-item *ngFor="let item of courseListsItems;trackBy: trackByFn" [courseListItem]="item" (delete)="onDelete($event)" (edit)="onEdit($event)"></app-course-list-item>
    </div>
    <button class="btn btn-link bg-white btn-lg btn-block mb-5" (click)="loadMoreCourses()">LOAD MORE</button>
</div>
