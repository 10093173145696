<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <a class="navbar-brand" href="#">
        <img src="../../assets/logo.png" alt="">
         VIDEO COURSE
    </a>
    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" href="./">
                    <fa-icon [icon]="faUser"></fa-icon>
                     User Login
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="./">
                    <fa-icon [icon]="faSignOutAlt"></fa-icon>
                     Log off
                </a>
            </li>
        </ul>
    </div>
</nav>
